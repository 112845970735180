import React, { useState, useEffect } from "react";
import api from "../../services/api";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    TablePagination,
    Typography,
    Box,
    IconButton,
    Tooltip,
    Select,
    MenuItem
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
        marginTop: "1rem"
    },
    stats: {
        display: "flex",
        gap: theme.spacing(2)
    },
    statBox: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        textAlign: "center",
        minWidth: "100px"
    },
    downloadButton: {
        marginLeft: theme.spacing(2)
    },
    select: {
        minWidth: 150,
        marginLeft: theme.spacing(2)
    }
}));

const MessageTable = ({
    groupList, selectedGroupId,
    searchTerm, setSearchTerm,
    startDate, endDate, messages,
    setMessages, totalMessages,
    setTotalMessages, page, setPage,
    users, setSelectedUser, selectedUser }) => {
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

    useEffect(() => {
        console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", users)
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 1500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        const ticketId = groupList.find((groupFound) => Number(groupFound.id) === Number(selectedGroupId));

        const fetchMessages = async () => {
            try {
                const response = await api.get("/groupsMessages", {
                    params: {
                        page: page + 1,
                        searchParam: debouncedSearchTerm,
                        ticketId: ticketId?.ticketId || null,
                        startDate,
                        endDate,
                        userId: selectedUser || null
                    }
                });
                setMessages(response.data.messages);
                setTotalMessages(response.data.Total);
            } catch (error) {
                console.error("Error fetching messages:", error);
            }
        };

        fetchMessages();
    }, [page, debouncedSearchTerm, selectedGroupId, selectedUser]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setPage(0);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleUserChange = (event) => {
        setSelectedUser(event.target.value);
        setPage(0);
    };

    const highlightText = (text, highlight) => {
        if (!highlight) return text;
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
                <strong key={index}>{part}</strong>
            ) : (
                part
            )
        );
    };

    const handleDownloadAllMessages = async () => {
        try {
            const response = await api.get("/groupsMessages/all", {
                params: {
                    searchParam: debouncedSearchTerm,
                    ticketId: groupList.find(group => Number(group.id) === Number(selectedGroupId))?.ticketId || null,
                    startDate,
                    endDate,
                    userId: selectedUser || null
                }
            });
            const allMessages = response.data.messages;

            const formattedData = allMessages.map((msg) => ({
                Mensagem: msg.messageBody,
                Data: moment(msg.messageDate).format("DD/MM/YYYY HH:mm"),
                Grupo: groupList.find((group) => Number(group.ticketId) === msg?.ticketId)?.name || "Privado",
                Usuario: msg.user || "Externo"
            }));

            const worksheet = XLSX.utils.json_to_sheet(formattedData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Mensagens");

            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: "application/octet-stream" });
            saveAs(data, `mensagens_${moment().format("YYYYMMDD_HHmmss")}.xlsx`);
        } catch (error) {
            console.error("Error downloading messages:", error);
        }
    };

    return (
        <Paper>
            <Box className={classes.header}>
                <Box className={classes.stats}>
                    <Box className={classes.statBox}>
                        <Typography variant="h6">Total</Typography>
                        <Typography variant="h4">{totalMessages}</Typography>
                    </Box>
                    {messages.length > 0 && (
                        <Box className={classes.statBox}>
                            <Typography variant="h6">Mensagem</Typography>
                            <Typography variant="body1">
                                {searchTerm}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <TextField
                    label="Pesquisar"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <Select
                    className={classes.select}
                    value={selectedUser}
                    onChange={handleUserChange}
                    displayEmpty
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300,
                                overflowY: "auto",
                            },
                        },
                    }}  
                >
                    <MenuItem value="">Todos os Usuários</MenuItem>
                    {users?.length > 0 ? users?.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.name}
                        </MenuItem>
                    )): []}
                </Select>
                <Tooltip title="Baixar mensagens" arrow>
                    <IconButton
                        color="primary"
                        className={classes.downloadButton}
                        onClick={handleDownloadAllMessages}
                    >
                        <CloudDownloadIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Mensagem</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Grupo</TableCell>
                            <TableCell>Usuário</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messages.map((msg, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        {highlightText(msg.messageBody, searchTerm)}
                                    </TableCell>
                                    <TableCell>{moment(msg.messageDate).format("DD/MM/YYYY HH:mm")}</TableCell>
                                    <TableCell>{groupList.find((group) => Number(group.ticketId) === msg?.ticketId)?.name || "Privado"}</TableCell>
                                    <TableCell>{users?.find((user) => Number(user.id) === Number(msg?.user))?.name || "Externo"}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={totalMessages}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </Paper>
    );
};

export default MessageTable;
