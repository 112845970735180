import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import clsx from "clsx";

import { Paper, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInputCustom/";  
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtonsCustom";
import MessagesList from "../MessagesList";
import api from "../../services/api";

import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { EditMessageProvider } from "../../context/EditingMessage/EditingMessageContext";

import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    maxWidth: "100vw"
  },

  mainWrapperShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRadius: "8px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
    marginRight: 0,
  },
}));

const Ticket = ({ drawerOpen, setDrawerOpen, setMobileUserOpen, setMobileAdditionalInfoOpen }) => {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});

  useEffect(() => {
    setLoading(true);
    const getAll = async (params) => {
      const { data } = await api.request({
        url: '/settings',
        method: 'GET',
        params
      });
      return data;
    }
    
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const settings = await getAll();
          const visibleTicket = settings.some((setting) => {
            return (setting?.key === "userViewTicketsWithoutQueue" &&
              setting?.value === "enabled")
          });
          const { data } = await api.get("/tickets/u/" + ticketId);
          const { queueId } = data;
          const { queues, profile } = user;

          const queueAllowed = queues.find((q) => q.id === queueId);
          if (queueAllowed === undefined && profile !== "admin" && !visibleTicket) {
            toast.error("Acesso não permitido");
            history.push("/tickets");
            return;
          }

          setContact(data.contact);
          setTicket(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, user, history]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`));

    socket.on(`company-${companyId}-ticket`, (data) => {
      console.log("VE ISSO TRUTA", data)
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        history.push("/tickets");
      }
    });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, ticket, history]);

  const handleDrawerOpen = () => {
    if (isMobile) {
      setMobileAdditionalInfoOpen(true);
      return
    }
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    if (isMobile) {
      setMobileAdditionalInfoOpen(false);
      return
    }
    setDrawerOpen(false);
  };

  const renderTicketInfo = () => {
    if (ticket.user !== undefined) {
      return (
        <TicketInfo
          contact={contact}
          ticket={ticket}
          onClick={handleDrawerOpen}
        />
      );
    }
  };

  const renderMessagesList = () => {
    return (
      <>
        <MessagesList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
          user={user}
        ></MessagesList>
        <MessageInput ticketId={ticket.id} ticketStatus={ticket.status} ticket={ticket} isGroup={ticket.isGroup}/>
      </>
    );
  };

  return (
    <div className={`${classes.root} ${!drawerOpen ? "cmp-drawer--closed" : ""}`} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          {renderTicketInfo()}
          <TicketActionButtons setMobileAdditionalInfoOpen={setMobileAdditionalInfoOpen} setMobileUserOpen={setMobileUserOpen} contact={contact} handleDrawerOpen={handleDrawerOpen} drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} ticket={ticket} />
        </TicketHeader>
        <ReplyMessageProvider>
          <div style={{ background: theme.palette.primary.secondGradient }} className="cmp-message-chat__contianer"><h5 style={{fontWeight: "400"}}>Atribuido á {ticket.user && `${ticket.user.name}`}</h5></div>
          {/* <ForwardMessageProvider> */}
          <EditMessageProvider>
            {renderMessagesList()}
          </EditMessageProvider>
          {/* </ForwardMessageProvider> */}
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        user={user}
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
        ticket={ticket}
      />
    </div >
  );
};

export default Ticket;
