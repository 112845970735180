import React, { useState } from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import moment from "moment";

import Rating from "@material-ui/lab/Rating";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  on: {
    color: green[600],
    fontSize: "20px",
  },
  off: {
    color: red[600],
    fontSize: "20px",
  },
  pointer: {
    cursor: "pointer",
  },
  tableHeader: {
    background: theme.palette.primary.secondGradient,
    color: theme.palette.blackOrWhite
  },
  searchInput: {
    marginBottom: theme.spacing(2),
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
  },
  downloadButton: {
    marginLeft: theme.spacing(2),
  },
}));

export function RatingBox({ rating }) {
  const ratingTrunc = rating === null ? 0 : Math.trunc(rating);
  return <Rating defaultValue={ratingTrunc} max={3} readOnly />;
}

export default function TableAttendantsStatus(props) {
  const { loading, attendants, isAGroup, groupsFound } = props;

  const classes = useStyles();

  const dataToRender = isAGroup
    ? groupsFound?.messageReport.map((group) => ({
      userName: group.userName,
      messageCount: group.messageCount,
      lastMessageTime: group.lastMessageTime
        ? moment(group.lastMessageTime).format("DD/MM/YYYY HH:mm:ss")
        : "N/A",
      inactivityDuration: group.inactivityDuration || "N/A",
    }))
    : attendants;

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filteredData = dataToRender?.filter((item) =>
    (item.userName || item.name).toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderList = () => {
    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return paginatedData.map((a, k) => (
      <TableRow key={k}>
        <TableCell>{a.userName || a.name}</TableCell>
        {isAGroup ? (
          <>
            <TableCell align="center">{a.messageCount}</TableCell>
            <TableCell align="center">{a.lastMessageTime}</TableCell>
            <TableCell align="center">{a.inactivityDuration}</TableCell>
          </>
        ) : (
          <>
            <TableCell
              align="center"
              title="1 - Insatisfeito, 2 - Satisfeito, 3 - Muito Satisfeito"
              className={classes.pointer}
            >
              <RatingBox rating={a.rating} />
            </TableCell>
            <TableCell align="center">{formatTime(a.avgSupportTime, 2)}</TableCell>
            <TableCell align="center">{formatTime(a.avgWaitTime)}</TableCell>
            <TableCell align="center">
              {a.online ? (
                <CheckCircleIcon className={classes.on} />
              ) : (
                <ErrorIcon className={classes.off} />
              )}
            </TableCell>
          </>
        )}
      </TableRow>
    ));
  };

  function formatTime(minutes) {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m] ss[s]");
  }

  const exportData = () => {
    const exportData = filteredData.map(item => ({
      Nome: item.userName || item.name,
      Mensagens: item.messageCount || '',
      "Última Mensagem": item.lastMessageTime || '',
      Inatividade: item.inactivityDuration || '',
      Avaliações: item.rating || '',
      "T.M. de Atendimento": item.avgSupportTime || '',
      "T.M. de Espera": item.avgWaitTime || '',
      Status: item.online ? 'Online' : 'Offline',
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Dados");

    const fileName = isAGroup ? "atendentes_grupos.xlsx" : "dados_atendentes.xlsx";
    XLSX.writeFile(wb, fileName);
  };


  return !loading ? (
    <>
      <div style={{display: "flex"}} className={classes.searchInput}>
        <Tooltip title="Baixar Planilha" arrow>
          <IconButton
            color="primary"
            className={classes.downloadButton}
            onClick={exportData}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
        <TextField
          label="Buscar por Nome"
          variant="outlined"
          fullWidth
          
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>Nome</TableCell>
              {isAGroup ? (
                <>
                  <TableCell align="center">Mensagens</TableCell>
                  <TableCell align="center">Última Mensagem</TableCell>
                  <TableCell align="center">Inatividade</TableCell>
                </>
              ) : (
                <>
                  <TableCell align="center">Avaliações</TableCell>
                  <TableCell align="center">T.M. de Atendimento</TableCell>
                  <TableCell align="center">T.M. de Espera</TableCell>
                  <TableCell align="center">Status (Atual)</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>{renderList()}</TableBody>
        </Table>
      </TableContainer>

      <div className={classes.pagination}>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </div>
    </>
  ) : (
    <Skeleton variant="rect" height={150} />
  );
}
