import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { BlurCircular, CloudDownload } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
import api from '../../../../../services/api';

const ExportButton = ({ groupsFound, groupList, selectedGroupId, searchTerm, selectedUser }) => {
    const [loading, setLoading] = useState(false);

    const getFileNamePrefix = () => {
        if (!selectedGroupId) return 'TODOS_';
        const foundItem = groupList.find((group) => Number(group.id) === Number(selectedGroupId));
        return foundItem ? `${foundItem.name}_` : 'TODOS_';
    };

    const generateSheet = (data, sheetName) => {
        return XLSX.utils.json_to_sheet(data, { sheet: sheetName });
    };

    const handleExport = async () => {
        setLoading(true);

        const zip = new JSZip();
        const prefix = getFileNamePrefix();

        if(searchTerm !== "") {
            const response = await api.get("/groupsMessages/all", {
                params: {
                    searchParam: searchTerm,
                    ticketId: groupList.find(group => Number(group.id) === Number(selectedGroupId))?.ticketId || null,
                    userId: selectedUser || null
                }
            });
    
            const allMessages = response.data.messages;
    
            const formattedData = allMessages.map((msg) => ({
                Mensagem: msg.messageBody,
                Data: moment(msg.messageDate).format("DD/MM/YYYY HH:mm"),
                Grupo: groupList.find((group) => Number(group.ticketId) === msg?.ticketId)?.name || "Privado",
                Usuário: msg.user
            }));
    
            const messagesSheet = generateSheet(formattedData, "Mensagens");
            const messagesWorkbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(messagesWorkbook, messagesSheet, "Mensagens");
            const messagesBuffer = XLSX.write(messagesWorkbook, { bookType: "xlsx", type: "array" });
            zip.file(`${prefix}Mensagens.xlsx`, messagesBuffer);
        }

        const messageReportSheet = generateSheet(groupsFound.messageReport, 'Message Report');
        const messageReportWb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(messageReportWb, messageReportSheet, 'Message Report');
        const messageReportBuffer = XLSX.write(messageReportWb, { bookType: 'xlsx', type: 'array' });
        zip.file(`${prefix}Message_Report.xlsx`, messageReportBuffer);

        const groupTicketSummarySheet = generateSheet([groupsFound.groupTicketSummary], 'Group Ticket Summary');
        const groupTicketSummaryWb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(groupTicketSummaryWb, groupTicketSummarySheet, 'Group Ticket Summary');
        const groupTicketSummaryBuffer = XLSX.write(groupTicketSummaryWb, { bookType: 'xlsx', type: 'array' });
        zip.file(`${prefix}Group_Ticket_Summary.xlsx`, groupTicketSummaryBuffer);

        const heatmapSheet = generateSheet(groupsFound.heatmapMessageReport, 'Heatmap Message Report');
        const heatmapWb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(heatmapWb, heatmapSheet, 'Heatmap Message Report');
        const heatmapBuffer = XLSX.write(heatmapWb, { bookType: 'xlsx', type: 'array' });
        zip.file(`${prefix}Heatmap_Message_Report.xlsx`, heatmapBuffer);

        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, `${prefix}Reports.zip`);
            setLoading(false);
        });
    };

    return (
        <div>
            <Tooltip title="Baixar todos os dados (incluindo Mensagens no ZIP)">
                <IconButton
                    color="primary"
                    onClick={handleExport}
                    disabled={loading}
                >
                    {loading ? (
                        <BlurCircular style={{ color: 'gray' }} />
                    ) : (
                        <CloudDownload />
                    )}
                </IconButton>
            </Tooltip>

            {loading && (
                <div style={{ marginTop: '10px', color: 'gray' }}>
                    Exportando planilhas, por favor aguarde...
                </div>
            )}
        </div>
    );
};

export default ExportButton;
