import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TableRowSkeleton from "../../../../components/TableRowSkeleton";
import baileyIcon from "../../../../assets/baileys-icon.png";
import { i18n } from "../../../../translate/i18n";
import { Can } from "../../../../components/Can";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { AccessTime } from "@material-ui/icons";

const ContactsTable = ({
    classes,
    contacts,
    loading,
    user,
    handleOpenUserSelectModal,
    setContactTicket,
    setNewTicketModalOpen,
    hadleEditContact,
    setConfirmOpen,
    setDeletingContact,
    handleTicketClick
}) => {
    const hideContactNumberActive = localStorage.getItem("hideContactNumberActive") === "true";
    
    return (
        <Paper
            className={classes.mainPaper}
            variant="outlined"
        >
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox" />
                        <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                        <TableCell align="center">
                            {i18n.t("contacts.table.whatsapp")}
                        </TableCell>
                        <TableCell align="center">
                            {i18n.t("contacts.table.email")}
                        </TableCell>
                        <TableCell align="center">
                            {i18n.t("contacts.table.actions")}
                        </TableCell>
                        <TableCell align="center">
                            Dono do contato
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>
                        {!loading && contacts.map((contact) => (
                            <TableRow key={contact.id}>
                                <TableCell style={{ paddingRight: 0, width: '65px', height: '65px' }}>
                                    <div style={{ position: 'relative', width: '65px', height: '65px' }}>
                                        <Avatar
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "8px",
                                            }}
                                            src={contact.profilePicUrl}
                                        />

                                        <Avatar
                                            style={{
                                                position: "absolute",
                                                bottom: "0",
                                                right: "0",
                                                width: "20px",
                                                height: "20px",
                                                borderRadius: "4px",
                                                background: contact.number && (contact.contactType === "official")
                                                    ? "#25D366"  // Cor do WhatsApp
                                                    : contact.messengerId
                                                        ? "#3b5998" // Cor do Facebook
                                                        : contact.instagramId
                                                            ? "linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)" // Cor do Instagram
                                                            : "transparent",
                                            }}
                                        >
                                            {contact.contactType === "baileys" && (
                                                <img style={{ width: "25px" }} src={baileyIcon} alt="WhatsApp Bailey" />
                                            )}

                                            {contact.contactType === "official" && (
                                                <WhatsAppIcon style={{ color: "white", fontSize: "16px" }} />
                                            )}
                                            {contact.contactType === "facebook" && (
                                                <FacebookIcon style={{ color: "white", fontSize: "16px" }} />
                                            )}
                                            {contact.contactType === "instagram" && (
                                                <InstagramIcon style={{ color: "white", fontSize: "16px" }} />
                                            )}

                                        </Avatar>
                                    </div>
                                </TableCell>


                                <TableCell>{((!isNaN(contact.name) && hideContactNumberActive) && user.profile === "user") ? "SEM PERMISSÃO" : contact.name }</TableCell>
                                <TableCell align="center">{(hideContactNumberActive && user.profile === "user") ? "SEM PERMISSÃO" : contact?.number}</TableCell>
                                <TableCell align="center">{contact.email}</TableCell>
                                <TableCell align="center">
                                    {user.profile === 'admin' && (
                                        <IconButton
                                            size="small"
                                            onClick={() => handleOpenUserSelectModal(contact.id)}
                                        >
                                            <PersonAddIcon />
                                        </IconButton>
                                    )}
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setContactTicket(contact);
                                            setNewTicketModalOpen(true);
                                        }}
                                    >
                                        <ConfirmationNumberIcon />
                                    </IconButton>
                                    {(user?.name === contact?.user?.name || user.profile === 'admin' || contact?.user === undefined) && <IconButton
                                        size="small"
                                        onClick={() => hadleEditContact(contact.id, contact)}
                                    >
                                        <EditIcon />
                                    </IconButton>}
                                    {contact?.tickets?.length > 0 && <IconButton onClick={() => handleTicketClick(contact.tickets[0].id)}>
                                        <AccessTime />
                                    </IconButton>}
                                    <Can
                                        role={user?.name === contact?.user?.name || user.profile === 'admin' || contact?.user === undefined ? "admin" : "user"}
                                        perform="contacts-page:deleteContact"
                                        yes={() => (
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    setConfirmOpen(true);
                                                    setDeletingContact(contact);
                                                }}
                                            >
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        )}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    {(() => {
                                        if (contact.userName) {
                                            return contact.userName;
                                        }
                                        if (contact.isBlocked === undefined || contact?.extraInfo?.length === 0) {
                                            return "Feito/Adicionado";
                                        }
                                        return "Administrador";
                                    })()}
                                </TableCell>

                            </TableRow>
                        ))}
                        {loading && <TableRowSkeleton avatar columns={3} />}
                    </>
                </TableBody>
            </Table>
        </Paper>
    );
};

export default ContactsTable;
